export default {
  background: "rgb(19, 18, 23)",
  primary: "#bb86fc",
  links: "#bb86fc",
  secondary: "#03dac6",
  onBackground: "#ffffff",
  onPrimary: `#000000`,
  onSecondary: `#000000`,
  surface: "#1b191f",
  button: "#616161",
  formBorder: "black",
  navbarColor: "#1b191f",
  onNavbar: `white`,
  tag: "#616161",
  inlineTag: "#bb86fc",
  onInlineTag: "lighten(rgba(0, 0, 0, 0.87), 1%)",
  selectedTag: "#8f9bff",
  calloutBorder: "#bb86fc",
  headerOpacity: 0.87,
  textOpacity: 0.6,
}
